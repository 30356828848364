import styled from 'styled-components';

export const Container = styled.div`
  form {
    display: flex;
    margin-bottom: 24px;

    button {
      margin-left: 12px;
    }
  }
`;
